
<template>
 <div class="sign-in">
  <a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle">
   <a-col
    :span="24"
    :md="12"
    :lg="{ span: 12, offset: 0 }"
    :xl="{ span: 6, offset: 2 }"
    class="col-form"
   >
    <h3 class="mb-15">퍼즐 | 어드민 로그인</h3>
    <h5 class="font-regular text-muted">이메일과 패스워드를 입력해주세요</h5>
    <a-form
     id="components-form-demo-normal-login"
     :form="form"
     class="login-form"
     @submit="handleSubmit"
     :hideRequiredMark="true"
    >
     <a-form-item class="mb-10" label="이메일" :colon="false">
      <a-input
       v-decorator="[
        'email',
        { rules: [{ required: true, message: '이메일을 입력해주세요' }] },
       ]"
       placeholder="이메일"
      />
     </a-form-item>
     <a-form-item class="mb-5" label="패스워드" :colon="false">
      <a-input
       v-decorator="[
        'password',
        { rules: [{ required: true, message: '패스워드를 입력해주세요' }] },
       ]"
       type="password"
       placeholder="패스워드"
      />
     </a-form-item>
     <a-form-item>
      <a-button
       type="primary"
       block
       html-type="submit"
       class="login-form-button"
       >로그인</a-button
      >
     </a-form-item>
    </a-form>
   </a-col>
   <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
    <img src="images/undraw_In_the_office_re_jtgc.png" alt />
   </a-col>
  </a-row>
 </div>
</template>


<script>
import {
 getAuth,
 createUserWithEmailAndPassword,
 signInWithEmailAndPassword,
 setPersistence,
 updateProfile,
 signInWithPopup,
 GoogleAuthProvider,
 browserLocalPersistence,
} from "firebase/auth";
import {
 getFirestore,
 doc,
 collection,
 setDoc,
 getDocs,
 query,
 where,
} from "firebase/firestore";
const db = getFirestore();
const auth = getAuth();
const errorCodeSet = [
 {
  no: "E-001",
  code: "auth/claims-too-large",
  msg: "구글에서 제공되는 페이로드가 허용크기 인 1000 바이트를 초과합니다.",
 },
 {
  no: "E-002",
  code: "auth/email-already-exists",
  msg: "해당 이메일은 이미 기존 사용자가 사용 중입니다.",
 },
 {
  no: "E-003",
  code: "auth/id-token-expired",
  msg: "세션 토근이 만료 되었습니다",
 },
 {
  no: "E-004",
  code: "auth/id-token-revoked",
  msg: "세션 토근이 회수 되었습니다.",
 },
 {
  no: "E-005",
  code: "auth/insufficient-permission",
  msg: "해당 리소스에 접근할 권환이 없습니다.",
 },
 {
  no: "E-006",
  code: "auth/internal-error",
  msg: "요청을 처리하는 동안 인증 서버에서 예기치 않은 오류가 발생했습니다.",
 },
 {
  no: "E-007",
  code: "auth/invalid-argument",
  msg: "인증 방법에 잘못된 인수가 언급되었습니다.",
 },
 {
  no: "E-008",
  code: "auth/invalid-claims",
  msg: "사용자 지정 클레임 속성이 잘못되었습니다.",
 },
 {
  no: "E-009",
  code: "auth/invalid-continue-uri",
  msg: "유효한 URL 문자열이어야합니다.",
 },
 {
  no: "E-010",
  code: "auth/invalid-creation-time",
  msg: "생성시간은 유효한 UTC 형식이어야 합니다.",
 },
 {
  no: "E-011",
  code: "auth/invalid-credential",
  msg: "해당 사용자는 인증 작업을 수행하는 데 사용할 수 없습니다.",
 },
 {
  no: "E-012",
  code: "auth/invalid-disabled-field",
  msg: "비활성화 된 사용자 속성에 제공된 값이 잘못되었습니다.",
 },
 {
  no: "E-013",
  code: "auth/invalid-display-name",
  msg: "해당 사용자의 이름 값이 누락되었습니다. 사용자 이름은 필수 입니다.",
 },
 {
  no: "E-014",
  code: "auth/invalid-dynamic-link-domain",
  msg: "제공된 동적 링크 도메인이 현재 프로젝트에 승인되지 않았습니다.",
 },
 { no: "E-015", code: "auth/invalid-email", msg: "잘못된 이메일 주소 입니다." },
 {
  no: "E-016",
  code: "auth/invalid-email-verified",
  msg: "이메일 확인 속성이 잘못 되었습니다.",
 },
 {
  no: "E-017",
  code: "auth/invalid-hash-algorithm",
  msg: "해시 알고리즘이 잘못되었습니다.",
 },
 {
  no: "E-018",
  code: "auth/invalid-hash-block-size",
  msg: '해시 블록 사이즈가 숫자가 아닙니다."',
 },
 {
  no: "E-019",
  code: "auth/invalid-hash-derived-key-length",
  msg: "해시 블록 사이즈가 잘못 되었습니다.",
 },
 {
  no: "E-020",
  code: "auth/invalid-hash-key",
  msg: "유효한 해시 키 버퍼가 아닙니다.",
 },
 {
  no: "E-021",
  code: "auth/invalid-hash-memory-cost",
  msg: "유효한 해시 메모리 코스트 숫자가 아닙니다.",
 },
 {
  no: "E-022",
  code: "auth/invalid-hash-parallelization",
  msg: "유효한 해시 병렬 사이즈가 아닙니다.",
 },
 {
  no: "E-023",
  code: "auth/invalid-hash-rounds",
  msg: "유효한 해시 회차가 아닙니다.",
 },
 {
  no: "E-024",
  code: "auth/invalid-hash-salt-separator",
  msg: "유효한 해시 알고리즘 구분자 입니다.",
 },
 {
  no: "E-025",
  code: "auth/invalid-id-token",
  msg: "제공된 ID 토큰은 유효한 Firebase ID 토큰이 아닙니다.",
 },
 {
  no: "E-026",
  code: "auth/invalid-last-sign-in-time",
  msg: "마지막 로그인 시간은 유효한 UTC 날짜 문자열이어야합니다.",
 },
 {
  no: "E-027",
  code: "auth/invalid-page-token",
  msg: "페이지 토근이 유효하지 않습니다.",
 },
 {
  no: "E-028",
  code: "auth/invalid-password",
  msg: "비밀번호 가 올바르지 않습니다.",
 },
 {
  no: "E-028",
  code: "auth/wrong-password",
  msg: "비밀번호 가 올바르지 않습니다.",
 },
 {
  no: "E-029",
  code: "auth/invalid-password-hash",
  msg: "암호 해시는 유효한 바이트 버퍼 여야합니다.",
 },
 {
  no: "E-030",
  code: "auth/invalid-password-salt",
  msg: "유효하지 않은 값입니다.",
 },
 {
  no: "E-031",
  code: "auth/invalid-phone-number",
  msg: "전화번호가 올바르지 않습니다.",
 },
 {
  no: "E-032",
  code: "auth/invalid-photo-url",
  msg: "프로필 이미지 URL 값이 잘못되었습니다.",
 },
 {
  no: "E-033",
  code: "auth/invalid-provider-data",
  msg: "유효한 개체 배열이 아닙니다.",
 },
 {
  no: "E-034",
  code: "auth/invalid-provider-id",
  msg: "유효한 공급자 식별 코드가 아닙니다.",
 },
 {
  no: "E-035",
  code: "auth/invalid-session-cookie-duration",
  msg: "세션 쿠키 기간은 5 분에서 2 주 사이의 유효한 밀리 초 단위 여야합니다.",
 },
 {
  no: "E-036",
  code: "auth/invalid-uid",
  msg: "제공된 uid는 최대 128 자의 비어 있지 않은 문자열이어야합니다.",
 },
 {
  no: "E-037",
  code: "auth/invalid-user-import",
  msg: "가져올 사용자 레코드가 잘못되었습니다.",
 },
 {
  no: "E-038",
  code: "auth/maximum-user-count-exceeded",
  msg: "가져올 수있는 최대 사용자 수를 초과했습니다.",
 },
 {
  no: "E-039",
  code: "auth/missing-android-pkg-name",
  msg: "Android 앱을 설치해야하는 경우 Android 패키지 이름을 제공해야합니다.",
 },
 {
  no: "E-040",
  code: "auth/missing-continue-uri",
  msg: "유효한 URL이 아닙니다.",
 },
 {
  no: "E-041",
  code: "auth/missing-hash-algorithm",
  msg: "암호 해시 알고리즘이 누락되었습니다.",
 },
 {
  no: "E-042",
  code: "auth/missing-ios-bundle-id",
  msg: "요청에 iOS 번들 ID가 없습니다.",
 },
 {
  no: "E-043",
  code: "auth/missing-uid",
  msg: "현재 작업에는 uid 식별자가 필요합니다.",
 },
 {
  no: "E-044",
  code: "auth/operation-not-allowed",
  msg: "제공된 로그인 제공 업체가 Firebase 프로젝트에서 사용 중지되었습니다.",
 },
 {
  no: "E-045",
  code: "auth/project-not-found",
  msg: "프로젝트를 찾을수 없습니다.",
 },
 {
  no: "E-046",
  code: "auth/reserved-claims",
  msg: "사용자 클레임이 예약되어 있습니다.",
 },
 {
  no: "E-047",
  code: "auth/session-cookie-expired",
  msg: "세션 쿠키가 만료되었습니다.",
 },
 {
  no: "E-048",
  code: "auth/session-cookie-revoked",
  msg: "세션 쿠키가 회수되었습니다.",
 },
 {
  no: "E-049",
  code: "auth/uid-already-exists",
  msg: "제공된 uid는 이미 기존 사용자가 사용 중입니다. 각 사용자는 고유 한 uid를 가져야합니다.",
 },
 {
  no: "E-050",
  code: "auth/unauthorized-continue-uri",
  msg: "도메인이 허용 목록에 없습니다.",
 },
 { no: "E-051", code: "auth/user-not-found", msg: "사용자를 찾지 못했습니다." },
 {
  no: "E-052",
  code: "auth/too-many-requests",
  msg: "너무나 많은 로그인 시도 입니다.",
 },
 {
  no: "E-053",
  code: "auth/email-already-in-use",
  msg: "이미 사용중인 이메일 입니다.",
 },
 {
  no: "E-054",
  code: "auth/weak-password",
  msg: "패스워드가 너무 약합니다. 최소 6자리 이상 입력 하셔야 합니다.",
 },
];

import { showSuccess, showError } from "../components/fnc";
export default {
 data() {
  return {
   email: "",
   password: "",
  };
 },
 beforeCreate() {
  this.form = this.$form.createForm(this, { name: "normal_login" });
 },
 methods: {
  fncSetPersistence(newPayload) {
   const self = this;

   setPersistence(auth, browserLocalPersistence)
    .then(function () {
     self.login(newPayload);
    })
    .catch(function (err) {
     let _message = "";
     const _obj = errorCodeSet.find((item) => err.code === item.code);
     if (_obj) {
      _message = `[${_obj["no"]}] ${_obj["msg"]}`;
     } else {
      _message = err.message;
     }

     showError({
      notify: this.$vs.notify,
      msg: _message,
     });
    });
  },
  login(obj) {
   if (obj.email === "admin@puzzle.live") {
    signInWithEmailAndPassword(auth, obj.email, obj.password).then(
     (result) => {
      //
     },
     (err) => {
      let _message = "";

      const _obj = errorCodeSet.find((item) => err.code === item.code);
      if (_obj) {
       _message = `[${_obj["no"]}] ${_obj["msg"]}`;
      } else {
       _message = err.message;
      }

      showError({
       notification: this.$vs.notification,
       msg: _message,
      });
     }
    );
   } else {
    showError({
     notification: this.$vs.notification,
     msg: "Who are you?",
    });
   }
  },
  handleSubmit(e) {
   e.preventDefault();
   const self = this;
   this.form.validateFields((err, values) => {
    if (!err) {
     self.fncSetPersistence(values);
    }
   });
  },
 },
 mounted() {},
};
</script>

<style lang="scss">
body {
 background-color: #ffffff;
}
</style>